<template>
  <TransitionFade>
    <Button
      v-if="!isMenuOpen"
      id="express-booking-toggle"
      ref="target"
      :theme="BUTTON_THEME.PRIMARY"
      :size="BUTTON_SIZE.LG"
      class="fixed bottom-8 right-8 z-30 w-16 transform justify-center !rounded-full leading-[18px] transition-all duration-150 hover:scale-105 data-[prefix]:!pl-5 data-[prefix]:!pr-5 md:w-36 [&>span]:hidden [&>span]:text-left [&>span]:md:inline-flex [&>span]:md:w-16"
      :title="t('btn.toggle')"
      :truncate="false"
      :icon="SVG_ICONS.EXPRESS_TICKET"
      :text="t('btn.expressBooking')"
      @click="toggle"
    />
  </TransitionFade>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { toggle, isMenuOpen } = useExpressBooking()

defineOptions({
  name: 'ExpressBookingToggle',
})
</script>

<i18n>
de:
  btn:
    toggle: "Express Booking öffnen"
    expressBooking: "Express Booking"
es:
  btn:
    toggle: "Abrir reserva express"
    expressBooking: "Reserva express"
</i18n>
