<template>
<FormKitLazyProvider config-file="true" :default-config="false">
  <div class="contents">
    <div
      id="express-booking-desktop-bar"
      class="fixed bottom-0 right-0 z-[45] flex w-full flex-col"
    >
      <div
        class="border-primary bg-light dark:bg-dark flex h-20 w-full items-center justify-between border-t-[0.375rem]"
      >
        <div class="ml-4 flex w-full justify-center space-x-4">
          <FormKit
            v-model="selection"
            type="form"
            preserve
            :actions="false"
            :classes="{ form: 'flex z-30' }"
          >
            <Dropdown
              :open="isMovieSelectionOpen"
              :direction="DROPDOWN_DIRECTION.UP"
              :classes="{
                dropdown: 'md:w-64 xl:w-96 xl:max-h-96',
                trigger: 'md:w-64 xl:w-96 justify-between mr-2',
              }"
              :button-text="showGroupTriggerTitle"
              @open="isMovieSelectionOpen = true"
              @close="isMovieSelectionOpen = false"
            >
              <ShowGroupsExpressBookingStepShowGroup
                :cinema="cinema"
                @selected="isMovieSelectionOpen = false"
              />
            </Dropdown>

            <Dropdown
              :disabled="!selection.showGroup"
              :direction="DROPDOWN_DIRECTION.UP"
              :button-text="dateTriggerTitle"
              :open="isDateSelectionOpen"
              :classes="{
                dropdown: 'w-48 max-h-96',
                trigger: 'w-48 xl:w-72 justify-between mr-2',
              }"
              @open="isDateSelectionOpen = true"
              @close="isDateSelectionOpen = false"
            >
              <ShowGroupsExpressBookingStepDate
                v-if="selection.showGroup"
                :cinema="cinema"
                :show-group="selection.showGroup"
                @selected="isDateSelectionOpen = false"
              />
            </Dropdown>

            <Dropdown
              :open="isShowSelectionOpen"
              :disabled="!selection.date"
              :direction="DROPDOWN_DIRECTION.UP"
              :classes="{
                dropdown: 'w-56 max-h-96',
                trigger: 'w-56 xl:w-96 justify-between mr-2',
              }"
              :button-text="t('btn.showSelection')"
              @open="isShowSelectionOpen = true"
              @close="isShowSelectionOpen = false"
            >
              <ShowGroupsExpressBookingStepShow
                v-if="selection.showGroup && selection.date"
                :cinema="cinema"
                :show-group="selection.showGroup"
                :date="selection.date"
              />
            </Dropdown>
          </FormKit>
        </div>
        <Button
          :icon="SVG_ICONS.CLOSE"
          :theme="BUTTON_THEME.TERTIARY"
          class="ml-auto mr-2 md:mr-8"
          @click="toggle"
        />
      </div>
    </div>
    <BackgroundBlur :visible="true" z-index="z-40" @click.self="toggle" />
  </div>
</FormKitLazyProvider>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

defineProps<Props>()

defineOptions({
  name: 'ExpressBookingDesktop',
})

const { t } = useI18n()
const { selection, toggle } = useExpressBooking()

const isMovieSelectionOpen = ref(false)
const isDateSelectionOpen = ref(false)
const isShowSelectionOpen = ref(false)
const showGroupTriggerTitle = computed(() =>
  selection.showGroupLabel ? selection.showGroupLabel : t('btn.movieSelection')
)
const dateTriggerTitle = computed(() =>
  selection.dateLabel ? selection.dateLabel : t('btn.dateSelection')
)
</script>

<i18n>
de:
  btn:
    movieSelection: 'Film auswählen'
    dateSelection: 'Datum auswählen'
    showSelection: 'Vorstellung auswählen'
es:
  btn:
    movieSelection: 'Seleccionar película'
    dateSelection: 'Seleccionar fecha'
    showSelection: 'Seleccionar función'
</i18n>
