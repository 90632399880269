<template>
  <div class="border-neutral-light-1 dark:border-neutral-dark-1 border-b">
    <div class="bg-primary h-1.5 w-full" />

    <div class="text-dark dark:text-light flex h-16 items-center">
      <InlineSvg
        :name="SVG_ICONS.TICKET_CONFIRMATION_OUTLINE"
        class="m-4 h-6 w-6 fill-current"
      />

      <div class="flex-grow text-3xl" v-text="t('title')" />

      <Button
        class="m-4"
        :theme="BUTTON_THEME.TERTIARY"
        :icon="SVG_ICONS.CLOSE"
        @click="toggle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: 'ShowGroupsExpressBookingMobileHeader',
})

const { t } = useI18n()
const { toggle } = useExpressBooking()
</script>

<i18n>
de:
  title: "Expressbuchung"
es:
  title: "Reserva express"
</i18n>
