<template>
<FormKitLazyProvider config-file="true" :default-config="false">
  <ShowGroupsExpressBookingFetch
    :fetch-key="EXPRESS_BOOKING_STEP.MOVIE"
    :fetch-params="{
      cinemaId: cinema.id,
      dates: [],
      filters: [FILTER_OPTION.SHOW_GROUPS],
    }"
  >
    <template #default="{ showGroups }">
      <FormKit
        type="radio"
        name="showGroup"
        :options="mapValues(showGroups)"
        :classes="{
          inner: '$reset hidden',
          wrapper: 'md:border-none',
          label: 'p-4 md:py-2',
          option: 'md:border-none',
          options: 'md:space-y-0',
        }"
        @input="handleInput(showGroups, $event as string)"
      />

      <FormKit type="hidden" name="showGroupLabel" />
    </template>
  </ShowGroupsExpressBookingFetch>
</FormKitLazyProvider>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import type { Cinema, FilterOptionValue } from '#gql/default'

defineOptions({
  name: 'ShowGroupsExpressBookingStepShowGroup',
})

interface Props {
  cinema: Cinema
}

defineProps<Props>()
const emit = defineEmits(['selected'])

const { currentStep, selection } = useExpressBooking()

function mapValues(values: FilterOptionValue[]) {
  return (
    values?.map((value: FilterOptionValue) => ({
      label: value.label,
      value: value.key,
    })) ?? []
  )
}

function handleInput(showGroups: FilterOptionValue[], value: string) {
  const selected = showGroups.find((showGroup) => showGroup.key === value)

  selection.showGroup = value
  selection.showGroupLabel = selected ? selected.label : ''
  currentStep.value = EXPRESS_BOOKING_STEP.DATE
  emit('selected')
}

onMounted(() => {
  selection.showGroup = ''
  selection.showGroupLabel = ''
})
</script>
