<template>
<FormKitLazyProvider config-file="true" :default-config="false">
  <ShowGroupsExpressBookingFetch
    :fetch-key="EXPRESS_BOOKING_STEP.DATE"
    :fetch-params="{
      cinemaId: cinema.id,
      showGroups: [showGroup],
      dates: [],
    }"
  >
    <template #default="{ dates }">
      <FormKit
        type="radio"
        name="date"
        :options="mapValues(dates)"
        :classes="{
          inner: '$reset hidden',
          wrapper: 'md:border-none',
          label: 'p-4 md:py-2',
          option: 'md:border-none',
          options: 'md:space-y-0',
        }"
        @input="handleInput(dates, $event as string)"
      />

      <FormKit type="hidden" name="dateLabel" />
    </template>
  </ShowGroupsExpressBookingFetch>
</FormKitLazyProvider>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import type { Cinema, FilterOptionValue } from '#gql/default'

defineOptions({
  name: 'ShowGroupsExpressBookingStepDate',
})

interface Props {
  cinema: Cinema
  showGroup: string
}

defineProps<Props>()
const emit = defineEmits(['selected'])

const { d } = useI18n()
const { currentStep, selection } = useExpressBooking()

const dateFormat = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
}

function mapValues(values: FilterOptionValue[]) {
  return (
    values?.map((value: FilterOptionValue) => ({
      label: d(new Date(value.key), dateFormat),
      value: value.key,
    })) ?? []
  )
}

function handleInput(dates: FilterOptionValue[], value: string) {
  const selected = dates.find((date) => date.key === value)

  selection.date = value
  selection.dateLabel = selected ? d(new Date(selected.key), dateFormat) : ''
  currentStep.value = EXPRESS_BOOKING_STEP.SHOW
  emit('selected')
}

onMounted(() => {
  selection.date = ''
  selection.dateLabel = ''
})
</script>
